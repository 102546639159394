exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-arriendos-js": () => import("./../../../src/pages/arriendos.js" /* webpackChunkName: "component---src-pages-arriendos-js" */),
  "component---src-pages-avaluos-js": () => import("./../../../src/pages/avaluos.js" /* webpackChunkName: "component---src-pages-avaluos-js" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-inmueble-jsx": () => import("./../../../src/pages/inmueble.jsx" /* webpackChunkName: "component---src-pages-inmueble-jsx" */),
  "component---src-pages-inmuebles-js": () => import("./../../../src/pages/inmuebles.js" /* webpackChunkName: "component---src-pages-inmuebles-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-nosotros-js": () => import("./../../../src/pages/nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */),
  "component---src-pages-pagos-online-js": () => import("./../../../src/pages/pagos-online.js" /* webpackChunkName: "component---src-pages-pagos-online-js" */),
  "component---src-pages-prestamos-js": () => import("./../../../src/pages/prestamos.js" /* webpackChunkName: "component---src-pages-prestamos-js" */),
  "component---src-pages-tratamiento-de-datos-personales-js": () => import("./../../../src/pages/tratamiento-de-datos-personales.js" /* webpackChunkName: "component---src-pages-tratamiento-de-datos-personales-js" */),
  "component---src-pages-ventas-js": () => import("./../../../src/pages/ventas.js" /* webpackChunkName: "component---src-pages-ventas-js" */)
}

